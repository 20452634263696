.incentiveProgressBarTitleText{
  text-align: center;
  font-weight: bold;
}

.incentiveProgressBarPadding{
  padding:0px;
}
.incentiveProgressBarToggle{
  float:right;
  height: 0px;
  position: relative;
  top: -.4rem;
  z-index: 999;
  button.incentive-progress-bar-btn{
    height: 10px;
    line-height: 0px;
    padding: 6px;
    font-size: 10px;
    border-radius: 0 0 5px 5px;
    background-color: #ffffff;
    box-shadow: inset 0 0 0 rgba(255,255,255,.15), 5px 5px 5px 0 rgba(0,0,0,.075);
  }
}
.incentiveProgressBarContainer{
  padding-top:14px;
  padding-bottom: 5px;
}

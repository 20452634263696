.progressRatioTextSmall {
  text-align:center;
  color: #3ea748;
  font-size: 18px;
  line-height: 18px;
  font-weight: bold;
  position: relative;
  top: -2px;
  width: 100%;
}
.progressRatioTextLarge {
  text-align:center;
  color: #3ea748;
  font-size: 20px;
  font-weight: bold;
  position: relative;
  top: -2px;
  width: 100%;
}
.incentiveRatioFillSmall{
  text-align:center;
  color: #3ea748;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  top: -5px;
  width: 100%;
  line-height: 4px;
}
.incentiveRatioFillSmall .circularFill{
  width: 35px;
  top: -6px;
  position: relative;
  margin: auto;
}
.incentiveRatioFillLarge .circularFill{
  width: 38px;
  top: 0px;
  position: relative;
  margin: auto;
  .ratio-display-icon{
    font-size: 30px;
    line-height: inherit;
  }
}
.CircularProgressbar{
  .CircularProgressbar-path {
    stroke: #3ea748;
  }
}
#mobile-modal{
  text-align: center;
}
.mobile-modal-header-text{
  font-weight: bold;
}
.mobile-modal-right-border{
  border-right: 1px solid #dee2e6;
}
#genToken{
  font-size: 24px; font-weight: bold;
}
#resetToken{
  font-size: 24px; font-weight: bold;
}

.barSegment{
  margin: 1px;
  border-radius: 10px;
  height: 10px;
}
.incentiveBarHeight.progress{
  height:10px;
}
.incentiveBarMargin{
  margin-top: 9px;
}
.incentiveBar{
  height:10px;
  margin-top: 9px;
  margin-left: 1px;
  margin-right: 1px;
}
.completedSegment{
  background-color: green;
}
.incompleteSegment{
  background-color: grey;
}
.failedSegment{
  background-color: red;
}
.completedSegmentPopover .segmentPopoverText{
  color: green;
  font-weight: bold;
}
.incompleteSegmentPopover .segmentPopoverText{
  color: grey;
  font-weight: bold;
}
.failedSegmentPopover .segmentPopoverText{
  color: red;
  font-weight: bold;
}
.segmentStatusIcon{
  border: 1px solid #889687;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  margin: auto;
  position: relative;
  top: -6px;
  background-color: #fff;
  color: #889687;
  font-size: 12px;
  text-align:center;
  .segment-icon-complete{
    color: green;
    font-size: 18px;
  }
}


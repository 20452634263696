@import 'src/variables';
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.popover-body .card-group .main-nav-card:not(:first-child){
  border-left:1px solid darkgray;
 }

/**
  Main menu styling
 */
#main-menu-top {
  background-color: $btn-primary-background-color;
  .dynamic-main-menu-top-row.v7 {
    padding-bottom: 3px;


    .menu-item-text {
      a:not(.submenu) {
        color:#ffffff;
        cursor:pointer;
      }
      a.submenu {
        color:#058299;
        cursor:pointer;
      }
    }

    .top-nav-container {
      margin: auto;
      display: inline-flex;

      .nav-item{
        height:100%;
      }
    }
    /**
  Main menu nested popover styling
 */
    .main-nav-card{
      max-height: 200px;
      border:0;

      .main-nav-card-container {
        height:100%;
        text-align: center;
        color:$btn-primary-background-color;

        .card-body {
          padding:unset;
          .card-title{
            margin-bottom: unset;
          }
        }

        a {
          cursor:pointer;
          height:100%;
        }

        img{
          max-width:250px;
        }
      }
    }
    .menu-item-selected{
      font-weight:bold;
      border-bottom:3px solid white;
    }
    .child-menu-item-selected{
      font-weight:bold;
    }
  }

  .menu-item-icon-has-children {
    font-size: 10px;
  }

  .skeleton {
    .bone {
      height: 40px;
    }
  }
}


.errorComponent {
  //display:flex;

  .errorCodeArea{
    text-align: center;
    display: flex; 
    flex-direction: column;
    padding: 3em; 
    border-right:2px solid #ddd;
    font-weight:bold;
    justify-content:center;
    
    .errorCodeTitle{
      font-size:1.8rem;
      color: #3992a2;
    }
    .errorCode{
      font-size:4.1rem;
      margin-top:-2rem;
      color: #038096
    }
  }
  .errorTextArea{
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 3em;
    font-weight:bold;

    .errorTitle{
      font-size: 3rem;
    }
    .errorText{
      font-size: 1.5rem;
    }
    .buttonArea{
      margin-top: 1vw;
    }
  }
  + .errorImageArea{
    transform: translateY(-2vh);
    text-align: center;
    padding: 0 0 1vh;

    .errorImage{
      width: 50%;
    }
  }
}

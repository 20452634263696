@import 'src/variables';
 .quick-icon{
    font-size: 35px;
    color: $btn-primary-background-color;
  }
  .quick-icon-container{
    height:35px;
    .badge{
      position: relative;
      top: -2px;
      right: 10px;
      float: right;
    }
  }
  .quick-icon-text{
    font-size: 11px;
    color: black;
  }
  .quick-icon-wrapper{
    text-align: center;
    display: inline-block;
    margin:5px;
    cursor:pointer;
  }
  .icon-display-count-compressed{
    display:none;
  }

.quick-icons-menu-item {
  text-align: center;
  padding-bottom: 15px;
  .quick-icon{
    color:black;
  }
  .quick-icon-text{
    color:black;
  }
}

.quick-icons-visible{
  @media (max-width: 767px){
    .quick-icon{
      font-size: 20px;
      color: $btn-primary-background-color;
    }
    .quick-icon-container{
      display: none;
    }
    .quick-icon-text{
      display: none;
      color: black;
    }
    &.quick-icon-wrapper{
      text-align: center;
      display: inline-block;
      margin:1px 2px 2px 2px;
      cursor:pointer;
    }
  }
  @media (min-width: 768px){
    .quick-icon{
      font-size: 20px;
      color: $btn-primary-background-color;
    }
    
    .quick-icon-container{
      height:20px;
      .badge{
        position: relative;
        top: -2px;
        right: 10px;
        float: right;
      }
    }
    .quick-icon-text{
      font-size: 11px;
      color: black;
    }
    .quick-icon-wrapper{
      text-align: center;
      display: inline-block;
      margin:15px 2px 2px 2px;
      cursor:pointer;
    }
    .icon-display-count-compressed{
      display:none;
    }
  }
  @media (min-width: 992px){
    .quick-icon{
      font-size: 35px;
      color: $btn-primary-background-color;
    }
/*    .mini-icons .quick-icon{
      font-size: 25px;
      color: $btn-primary-background-color;
    }*/
    .quick-icon-container{
      height:35px;
      .badge{
        position: relative;
        top: -2px;
        right: 10px;
        float: right;
      }
    }
    .quick-icon-text{
      font-size: 11px;
      color: black;
    }
    .quick-icon-wrapper{
      text-align: center;
      display: inline-block;
      margin:10px;
      cursor:pointer;
    }
    .icon-display-count-compressed{
      display:none;
    }
  }

  .quick-icons-menu-item {
    text-align: center;
    padding-bottom: 15px;
    .quick-icon{
      color:$btn-primary-background-color;
    }
    .quick-icon-text{
      color:black;
    }
  }
  @media (min-width: 1200px){
    .icon-display-count-compressed{
      display:inline-block;
    }

  }
}
  .quick-icon.hamburger{
    font-size:45px;
    cursor:pointer;
  }



  /* Position and sizing of burger button */
  .bm-burger-button {
    position: relative;
    width: 36px;
    height: 30px;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #373a47;
  }

  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #a90000;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }

  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
    top:24px;
  }

  /* General sidebar styles */
  .bm-menu {
    background: #ffffff;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    top: -24px;
    position: relative;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
  }

  /* Styling of overlay */
  .bm-overlay {
    top:0;
    left:0;
  }

 .card-group .main-nav-collapsed-card.card {
      flex:unset;
    width:100%;

 }
 .main-menu-collapsed-items{
   width:100%;
   border-bottom: 1px solid gray;
 }

 .quick-icon-selected {
   font-weight: bold;
   border-bottom: 2px solid gray;
 }

 #quick-Icons-menu-hamburger .quick-icon-selected {
   background-color: #e9ecef;
   width: 100%;
   padding: 5px;
   margin: unset;
   border:unset;
   border-radius: 5px;
 }

.skiptocontent a{
  padding: 6px;
  position: absolute;
  top: 0px;
  left: -2500px;
  color: white !important;
  border-right: 1px solid white;
  border-bottom: 1px solid white;
  border-bottom-right-radius: 8px;
  background: #BF1722;
  -webkit-transition: top 1s ease-out;
  transition: top 1s ease-out;
  z-index: 1100;
}
.skiptocontent a:focus {
  position: absolute;
  left: 10px;
  top: 0px;
  outline-color: transparent;
  -webkit-transition: top .1s ease-in;
  transition: top .1s ease-in;
}
@import 'src/variables';
$submenu-image-border-radius: 20px;
/**
  Main menu styling
 */
  #dynamic-main-menu-top.v8 {
    background-color: $btn-primary-background-color;
    border-radius: 15px 15px 0 0;
    padding:20px 10px;
    font-family: 'PT Sans', sans-serif;
    font-weight: 700;

  &.dynamic-main-menu-top-row {
    display:flex;

    .menu-item-text {
      margin: 0 3px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      flex: 0 1 auto;

      a:not(.submenu) {
        color:#ffffff;
        cursor:pointer;
        padding:10px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      a.submenu {
        color:#058299;
        cursor:pointer;
      }
      &.menu-item-selected{
        background-color: white;
        border-radius: 5px;
        position: relative;

        a.nav-link{
          color: $btn-primary-background-color;
        }
      }
      &:hover{ // note: this is same as selected style
        background-color: white;
        border-radius: 5px;

        a.nav-link{
          color: $btn-primary-background-color;
        }
      }
      .has-submenu{
        position: absolute;
        top:100%;
      }
    }

    .top-nav-container {
      margin: auto;
      display: inline-flex;
      justify-content: center;
      width: 100%;
    }
  }
}
#v8-submenu-container {
  position: fixed;
  z-index: 1041;
  width: 100%;
  padding: 0;

  .pseudo-hover {
    opacity: 0.6;
  }

  .submenu-parent-pointer {
    top: -12px;
    position: absolute;
    overflow: visible;
    z-index: 1200;
  }

  /**
    Main menu nested popover styling
   */
  .main-nav-card {
    font-family: "PT Sans Narrow", sans-serif;
    font-weight: 700;
    font-size: 20px;
    max-height: 165px;
    max-width: 98%;
    overflow-x: auto;
    overflow-y: hidden;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    border: 0;
    border-radius: 15px;
    background-color: white;
    position: absolute;
    top: 100%;
    z-index: 1200;
    padding: 20px 0 20px 20px;

    &:has(> .main-nav-card-container-v){
      max-height: unset;
      overflow-x: hidden;
      overflow-y: auto;
    }

    /*
      HORIZONTAL ORIENTATION
     */
    .main-nav-card-container {
      height: 100%;
      text-align: center;
      color: $btn-primary-background-color;
      display: flex;
      align-items: flex-start;

      .card-body {
        padding: unset;

        .card-title {
          margin-bottom: unset;
        }
      }

      .main-nav-card-wrap {
        padding: 0 10px;

        &:first-of-type {
          padding-left: 0;
        }

        &:last-of-type {
          padding-right: 20px;
        }

        &.no-image {
          padding: 0 2rem;
          border-left: 1px solid #000;
          white-space: nowrap;

          &:first-of-type {
            border: none;
            padding-left: 0;
          }

          &:last-of-type {
            padding-right: 20px;
          }
        }


        a {
          padding: 0;

          &.submenu {
            cursor: pointer;
          }

          &.has-image {
            height: 125px;
          }

          .main-nav-card-image-container {
            position: relative;

            img {
              max-width: 250px;
              border-radius: $submenu-image-border-radius;
            }

            .main-nav-card-image-background {
              background: linear-gradient(180deg, rgba(255,255,255,0),80%, rgba(0,0,0,1));
              border-radius: $submenu-image-border-radius;
              position: absolute;
              top: 0;
              bottom: 0;
              right: 0;
              left: 0;
            }
          }

          .main-nav-card-title {
            transform: translateY(-1.7em);
            width: 100%;
            color: #eee;
          }

          .main-nav-card-title-no-image {
            color: #000;

            &:hover {
              color: $btn-primary-background-color;
            }
          }
        }
      }
    }

    /*
      VERTICAL ORIENTATION
     */
    .main-nav-card-container-v {
      max-height: 30vh;
      text-align: left;
      color: unset;
      display: flex;
      flex-direction: column;
      padding-right:20px;

      .nav-link.submenu {
        margin: 0 0 1rem;
        padding: 0;
        cursor: pointer;

        &:last-child {
          margin-bottom: 0;
        }

        &:hover {
          color: $btn-primary-background-color;
        }
      }
    }
  }
}
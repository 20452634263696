$light-overlay-background-color: rgb(255, 255, 255);
$light-overlay-color: rgb(0, 0, 0);
$dark-overlay-background-color: rgb(63, 89, 106);
$dark-overlay-color: rgb(255, 255, 255);
$overlay-text-background-color: rgba(0, 47, 84,0.7); //#002F54;
$overlay-text-background-opacity: 1;
$overlay-text-color: rgb(255, 255, 255);
$page-content-padding-x: 6rem;
$page-content-padding-y: 1rem;
$this-That-Size: 250px;
$btn-primary-background-color: #058299;
$btn-primary-text-color: #fff;
